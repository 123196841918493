import { mergeDeep } from "_utils/index"
import CursorMeshLines from "./CursorMeshLines"
import { Color, Vector3 } from "three"
import store from "_store"

export default class AmbientMeshLines extends CursorMeshLines {
	constructor(options = {}) {
		options = mergeDeep({
			visible: true,
			scene: null,
			name: 'AmbientMeshLines',
			sheetObjectName: 'Ambient Mesh Lines',
			lineCount: 16,
			maxPointCount: store.isLowTierGPU ? 100 : 200,
			groupIndex: 1,
			radius: store.isLowTierGPU ? 1.25 : 2,
			materialOpts: {
				uniforms: {},
				globalUniforms: {
					color: { value: new Color(0xd7823c) },
					uFadeColor: { value: new Color(0xba3801) },
					lineWidth: { value: 0.028, gui: { step: 0.001 } },

					opacity: { value: 0.6, gui: { step: 0.01, min: 0, max: 1 } },

					useDash: { value: false },
					dashOffset: { value: 0.1 },
					dashArray: { value: 0.1 },
					dashRatio: { value: 0.1 },
					visibility: { value: 1.0, gui: { step: 0.01, min: 0, max: 1 } },
					alphaTest: { value: 0.0, gui: { step: 0.01, min: 0, max: 1 } },

					uTailBegin: { value: store.isLowTierGPU ? 0.90 : 0.8, gui: { step: 0.01, min: 0, max: 1 } },
					uTailSmoothness: { value: store.isLowTierGPU ? 0.1 : 0.2, gui: { step: 0.01, min: 0, max: 1 } },

					uBeginColorEdge: { value: 0.24, gui: { step: 0.01, min: 0, max: 1 } },
					uFadeColorEdge: { value: 0.38, gui: { step: 0.01, min: 0, max: 1 } },

					uDarkenAmount: { value: 0.65, gui: { step: 0.01, min: 0, max: 1 } },

					uRevealProgress: { value: 1, gui: { step: 0.01, min: 0, max: 1 } }
				}
			}
		}, options)

		super(options)

		this._vec3 = new Vector3()
		this.pointer3D = new Vector3()

		this.uMouse = new Vector3()

		this.xFrequency = 0.6
		this.yFrequency = 0.6
	}

	animate() {
		this.update3DPointer()

		for (let i = 0; i < this.options.lineCount; i++) {
			this.lines[i].geometry.advance(
				this._targetPosition.copy(this.uMouse).add(
					this.lines[i].offset.addScalar(
						Math.sin(this.lines[i].randoms.x + store.WebGL.clock.elapsedTime * 3 * this.lines[i].randoms.z) * 0.015 * this.lines[i].randoms.y
					)
				)
			)
		}
	}

	update3DPointer() {
		this._vec3.set(0, 0, 0.5)

		this._vec3.x += Math.sin(this.options.scene.elapsedTime * this.options.scene.mouseOptions.xSpeed * this.options.scene.speedMultiplier) * this.xFrequency
		this._vec3.y += Math.cos(this.options.scene.elapsedTime * this.options.scene.mouseOptions.ySpeed * this.options.scene.speedMultiplier) * this.yFrequency

		this._vec3.unproject(this.options.scene.activeCamera)
		this._vec3.sub(this.options.scene.activeCamera.position).normalize()

		const distance = ((this.options.scene.activeCamera.position.z - 20) - this.options.scene.activeCamera.position.z) / this._vec3.z
		this.pointer3D.copy(this.options.scene.activeCamera.position).add(this._vec3.multiplyScalar(distance))

		this.pointer3D.x *= this.options.scene.mouseOptions.radius
		this.pointer3D.y *= this.options.scene.mouseOptions.radius
		// this.pointer3D.z = this.options.scene.activeCamera.position.z + Math.sin(this.options.scene.elapsedTime * this.options.scene.mouseOptions.depthFrequency) * this.options.scene.mouseOptions.depthMax + this.options.scene.mouseOptions.depthMin
		this.pointer3D.z = (this.options.scene.activeCamera.position.z - 20) + Math.sin(this.options.scene.elapsedTime * this.options.scene.mouseOptions.depthFrequency) * this.options.scene.mouseOptions.depthMax + this.options.scene.mouseOptions.depthMin

		this.uMouse.lerp(this.pointer3D, this.options.scene.mouseOptions.lerpMouseFactor)
	}

	reset() {
		super.reset()

		this.uMouse.set(0, 0, 0)
		this._vec3.set(0, 0, 0)
		this.pointer3D.set(0, 0, 0)
	}
}